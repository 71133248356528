<template>
    <div>
        <styled-interface
            no-padding
            no-overflow
            hide-toolbar>
            <div class="layout align-center row wrap">
                <div class="flex xs12 lg6 people bg-white">
                    <div class="d-flex h-screen justify-center align-center">
                        <div class="pb-26">
                            <div class="text-center heading-text">
                                Welcome to
                            </div>
                            <div class="layout align-center justify-center">
                                <img
                                    class="flex xs6"
                                    src="/img/BuyerBridge_LOGO_reverse.png">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex xs12 lg6">
                    <div class="align-center justify-center row fill-height">
                        <div v-if="step === null">
                            <loader class="my-5" />
                        </div>

                        <div v-if="step === 'createUser'">
                            <styled-card
                                :hide-toggle="true"
                                class="flex xs6 mx-auto">
                                <template #heading>
                                    Create a BuyerBridge Login
                                </template>

                                <div class="px-4 mx-4 pt-4">
                                    <p>The final step is to create a BuyerBridge login.</p>
                                </div>

                                <div class="px-4">
                                    <v-form
                                        id="sign-up-form"
                                        ref="form"
                                        v-model="valid"
                                        @submit.prevent="submitSignUpForm">
                                        <v-text-field
                                            v-model="organizationName"
                                            :rules="[...requiredRules]"
                                            label="Organization"
                                            type="text"
                                            class="styled-field ma-4" />
                                        <v-text-field
                                            v-model="fullName"
                                            :rules="[...requiredRules]"
                                            label="Full Name"
                                            type="text"
                                            class="styled-field ma-4" />
                                        <v-text-field
                                            v-model="email"
                                            :rules="[...emailRules, ...requiredRules]"
                                            label="Email"
                                            type="email"
                                            class="styled-field ma-4" />
                                        <v-text-field
                                            v-model="password"
                                            :rules="[...passwordRules, ...requiredRules, passwordsMatch]"
                                            label="Password"
                                            type="password"
                                            class="styled-field ma-4" />
                                        <v-text-field
                                            v-model="confirmPassword"
                                            :rules="[...passwordRules, ...requiredRules, passwordsMatch]"
                                            label="Confirm Password"
                                            type="password"
                                            class="styled-field ma-4" />

                                        <div class="ma-4 text-center">
                                            <styled-button
                                                :disabled="!valid"
                                                :loading="signUpRequestLoading"
                                                class="play-button"
                                                type="submit">
                                                Lets Go!
                                            </styled-button>
                                        </div>
                                        <v-alert
                                            class="ma-4"
                                            :value="signUpRequestError"
                                            color="error"
                                            icon="warning"
                                            outline>
                                            {{ signUpRequestErrorMessage || 'Error signing up. Please contact support.' }}
                                        </v-alert>
                                    </v-form>
                                </div>
                            </styled-card>
                        </div>
                    </div>
                </div>
            </div>
        </styled-interface>
    </div>
</template>

<script>
import axios from 'axios';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import StyledButton from '@/components/globals/StyledButton';
import { emailRules, requiredRules, passwordRules } from '@/helpers/validationRules';
import Loader from '@/components/globals/Loader.vue';
export default {
    components: {
        StyledInterface,
        StyledCard,
        StyledButton,
        Loader
    },
    data() {
        return {
            axios,
            agencyInvite: null,
            emailRules,
            requiredRules,
            passwordRules,
            response: null,
            resellerUuid: null,
            step: null,
            fbUrl: null,
            fbUrlError: false,
            fullName: '',
            email: '',
            password: '',
            confirmPassword: '',
            valid: false,
            signUpRequestLoading: false,
            signUpRequestError: false,
            signUpRequestErrorMessage: null,
            organizationName: null
        };
    },
    computed: {
        passwordsMatch() {
            return this.password == this.confirmPassword ? true : 'The passwords do not match.';
        }
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            // Set the axios credentials
            this.axios = axios.create();
            this.axios.defaults.headers.common['BB-Token'] = process.env.VUE_APP_BB_TOKEN;
            this.axios.defaults.baseURL = process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI;

            // Check if the uuid is in the query parameters
            if (this.$route.query.id) {
                // Check if the uuid in the query parameters is not the same as the uuid in local storage
                // If they are different clear the local storage and set the new uuid in local storage
                if (!(this.$route.query.id === localStorage.getItem('resellerUuid'))) {
                    this.clearLocalStorage();
                }
            }

            await this.setUuid();
        },
        setStepCreateUser() {
            this.step = 'createUser';
        },
        async setUuid() {
            if (this.$route.query.id) {
                localStorage.setItem('resellerUuid', this.$route.query.id);
            }

            this.resellerUuid = this.$route.query.id || localStorage.getItem('resellerUuid');
            await this.getAgencyInviteData();
        },
        clearLocalStorage() {
            localStorage.removeItem('resellerUuid');
        },
        submitSignUpForm() {
            this.$refs.form.validate();
            if (!this.valid) {
                return;
            }

            this.makeSignUpRequest();
        },
        async getAgencyInviteData() {
            try {
                const response = await this.axios.get(`/agency-invite/${this.resellerUuid}`);
                this.response = response.data;
                this.agencyInvite = response.data;

                // Set the organization name
                this.organizationName = this.response.name;

                this.setStepCreateUser();
            } catch (error) {
                console.log(error);
            }
        },
        async makeSignUpRequest() {
            try {
                this.signUpRequestLoading = true;
                this.signUpRequestError = false;
                this.signUpRequestErrorMessage = null;
                await this.axios.post('/agency-onboarding/sign-up', {
                    'uuid': this.resellerUuid,
                    'organization_name': this.organizationName,
                    'name': this.fullName,
                    'email': this.email,
                    'password': this.password,
                    'password_confirmation': this.confirmPassword,
                });

            } catch (error) {
                this.signUpRequestError = true;
                JSON.stringify(error.response.data).includes('The email has already been taken.') ? this.signUpRequestErrorMessage = 'The email has already been taken. Try a different email or contact support.' : null;
                return;
            } finally {
                this.signUpRequestLoading = false;
            }

            // Clear out anything used in local storage
            // for the sign up process
            this.clearLocalStorage();

            // Run the logout logic to make sure the UI behaves
            // correctly if someone that is already logged in signs up an agency
            await this.$store.dispatch('logout');

            // Make the login request and set the auth token
            await this.$store.dispatch('login', {
                email: this.email,
                password: this.password,
                init: true
            });

            // Push the user to the home page which will
            // require them to create the first account
            this.$router.push({ name: 'home' });
        }
    }
};
</script>

<style lang="scss" scoped>
.width-75 {
  width: 75%;
}

.fb-button {
  border-radius: 7px;
  text-align: center;
  background-color: #1A77F2;
  padding: 4px;
  color: white;
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
}

.bg-white {
  background-color: white;
}

.heading-text {
  font-size: 36px;
  color: #4FA2E9;
}

.pb-26 {
  padding-bottom: 260px;
}

@media (min-width: $bp-lg) {
  .h-screen {
    height: 100vh;
  }

  .people {
    background-image: url('/img/onboarding-people.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: left bottom;
    background-size: 700px;
    width: 50px;
  }
}
</style>

<style>
.interface-type-minimal .primary-container {
  padding: 0px !important;
}
</style>
